import React, { useState } from "react"
import { useTranslation } from "react-i18next"

function Donate() {
  const { t } = useTranslation()
  const [accepted, setAccepted] = useState(false)

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked)
  }

  const handlePayClick = () => {
    window.open("https://igw-demo.every-pay.com/lp/kcdkub", "_blank").focus()
  }

  return (
    <section className="section donate">
      <div className="container" style={{ textAlign: "center" }}>
        <h1 className="title mb-4">{t("donate")}</h1>
        <div className="box">
          <p className="subtitle is-5 mb-3">
            {t("recipient")}{" "}
            <strong>
              Moskva Patriarhaadi Eesti Õigeusu Kiriku Narva-Jõesuu Jumalaema
              Kaasani Ikooni Kogudus
            </strong>
          </p>
          <p className="mb-5">{t("paymentMethodSelection")}</p>
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={accepted}
                  onChange={handleCheckboxChange}
                />{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: t("readPolicy") }}
                ></span>
              </label>
            </div>
          </div>
          <p style={{ fontSize: "20pt" }}>{t("testPaymentText")}</p>
          <button
            className="button is-primary mt-4"
            onClick={handlePayClick}
            disabled={!accepted}
          >
            {t("pay")}
          </button>
        </div>
      </div>
    </section>
  )
}

export default Donate
