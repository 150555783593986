export const API_ENDPOINT = "https://kaasani-api.tomberg.tech"
export const API_LOGIN = `${API_ENDPOINT}/login`
export const API_PRAY_REQUEST = `${API_ENDPOINT}/pray`
export const API_NEWS = {
  ALL: `${API_ENDPOINT}/news/all`,
  CREATE: `${API_ENDPOINT}/news/create`,
  EDIT: (id) => `${API_ENDPOINT}/news/edit/${id}`,
  DELETE: (id) => `${API_ENDPOINT}/news/delete/${id}`,
  BY_ID: (id) => `${API_ENDPOINT}/news/${id}`,
  SET_ACTIVE: (id) => `${API_ENDPOINT}/news/setActive/${id}`,
}
export const API_EVENTS = {
  ALL: `${API_ENDPOINT}/events/all`,
  CREATE: `${API_ENDPOINT}/events/create`,
  EDIT: (id) => `${API_ENDPOINT}/events/edit/${id}`,
  DELETE: (id) => `${API_ENDPOINT}/events/delete/${id}`,
  BY_ID: (id) => `${API_ENDPOINT}/events/get/${id}`,
  SET_ACTIVE: (id) => `${API_ENDPOINT}/events/setActive/${id}`,
}
export const API_TEXTS = {
  BY_ID: (id) => `${API_ENDPOINT}/texts/${id}`,
  ALL: `${API_ENDPOINT}/texts/all`,
}
export const FILE = {
  ALL: `${API_ENDPOINT}/file/all`,
  UPLOAD: `${API_ENDPOINT}/file/upload`,
}
