import React from "react"

function Privacy() {
  return (
    <section className="section conditions">
      <div className="container" style={{ textAlign: "justify" }}>
        <h1 className="title is-4 mb-2 has-text-centered">
          Privaatsustingimused
        </h1>

        <h2 className="subtitle is-5 mb-2 has-text-centered">
          Isikuandmete töötlemise põhimõtted
        </h2>

        <h2 className="subtitle is-5 mt-3 mb-2">Andmete kogumine:</h2>
        <p>
          Meie veebisait ei kogu ühtki kasutaja isikuandmeid. Kõik annetused
          tehakse vabatahtlikult ning isiklikku teavet ei salvestata ega
          töödelda.
        </p>

        <h3 className="subtitle is-5 mt-5 mb-2">
          Andmete kasutamine ja säilitamine:
        </h3>
        <p>
          Kuna me ei kogu isikuandmeid, ei kasuta ega säilita me ka ühtegi
          isiklikku teavet. Kõik tehingud on korraldatud selliselt, et kasutaja
          privaatsus jääb tagatud.
        </p>

        <h3 className="subtitle is-5 mt-5 mb-2">
          Andmete turvalisus ja konfidentsiaalsus:
        </h3>
        <p>
          Hoolime oma kasutajate turvalisusest ja tagame, et kõik tehtud
          annetused on läbi viidud turvaliselt. Andmete konfidentsiaalsus on
          tagatud seades kõrged standardid, kuigi isikuandmeid me ei töödelda.
        </p>

        <h2 className="subtitle is-5 mt-5 mb-2">Märkused</h2>
        <p>
          Käesolevad tingimused kehtivad kõigile veebisaidil{" "}
          <strong>kaasani-kirik.ee</strong> tehtud annetustele. Küsimuste või
          täiendava teabe korral palume pöörduda e-posti aadressi{" "}
          <a href="mailto:kaasani.kirik@gmail.com">kaasani.kirik@gmail.com</a>{" "}
          poole.
        </p>

        <p className="mt-5">
          <strong>Aadress:</strong> Vabaduse tn 63 Narva-Jõesuu, Narva-Jõesuu
          Ida-Virumaa 29023, Eesti
        </p>
        <p>
          <strong>
            Moskva Patriarhaadi Eesti Õigeusu Kiriku Narva-Jõesuu Jumalaema
            Kaasani Ikooni Kogudus
          </strong>
        </p>
        <p>
          <strong>Registrikood:</strong> 80202491
        </p>
        <p>
          <strong>E-post:</strong>{" "}
          <a href="mailto:kaasani.kirik@gmail.com">kaasani.kirik@gmail.com</a>
        </p>
        <p>
          <strong>Telefoninumber:</strong> +372 512 57 96
        </p>
      </div>
    </section>
  )
}

export default Privacy
