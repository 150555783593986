import React, { useEffect } from "react"
import "./App.css"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import Login from "./components/Login"
import { ToastContainer } from "react-toastify"
import ManageNews from "./components/ManageNews"
import Home from "./components/Home"
import Header from "./components/Header"
import { useDispatch, useSelector } from "react-redux"
import {
  setEvents,
  setLoading,
  setNews,
  setTexts,
} from "./app/reducers/dataSlice"
import { API_EVENTS, API_NEWS, API_TEXTS } from "./utils/constants"
import { get } from "./utils/axios_utils"
import ManageEvents from "./components/ManageEvents"
import News from "./components/News"
import Footer from "./components/Footer"
import "@fortawesome/fontawesome-free/css/all.min.css"
import Schedule from "./components/Schedule"
import NewsItem from "./components/NewsItem"
import "./font/TriodionUnicode.ttf"
import PrayRequest from "./components/PrayRequest"
import ReactGA from "react-ga4"
import History from "./components/History"
import NotFound from "./components/NotFound"
import Privacy from "./components/Privacy"
import Return from "./components/Return"
import Donate from "./components/Donate"
ReactGA.initialize("G-Z5E6FG8B70")

function App() {
  const news = useSelector((state) => state.data.news)
  const hasAccess = useSelector((state) => state.auth.hasAccess)
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.data.loading)

  useEffect(() => {
    async function fetchData() {
      dispatch(setLoading(true))
      const news = await get(API_NEWS.ALL)
      const events = await get(API_EVENTS.ALL)
      //const texts = await get(API_TEXTS.ALL)
      // dispatch(setTexts(texts))
      dispatch(setNews(news))
      dispatch(setEvents(events))
      dispatch(setLoading(false))
    }
    fetchData()
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])

  return (
    <div className="App">
      <Router>
        <div className="container">
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/admin/manage-news" element={<ManageNews />} />
            <Route path="/admin/manage-events" element={<ManageEvents />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsItem />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/pray-request" element={<PrayRequest />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/return" element={<Return />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/history" element={<History />} /> */}
          </Routes>
        </div>
        <Footer />
      </Router>
      <ToastContainer />
    </div>
  )
}

export default App
