import React from "react"
import { useSelector } from "react-redux"
import { formatDate } from "../utils/util_funcs"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"

function News() {
  const news = useSelector((state) => state.data.news)
  const { t, i18n } = useTranslation()
  const isEe = i18n.language === "est"
  const navigate = useNavigate()
  return (
    <div className="flex flex-wrap justify-center items-center gap-2">
      {news.map((item, idx) => {
        if (item.active) {
          return (
            <>
              <div
                key={idx}
                className="news-card flex flex-column tablet-flex-row desktop-flex-row gap-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/news/${item.id}`, { state: item })
                }}
              >
                <div className="news-card-image">
                  <img
                    src={item.preview}
                    alt={`News Image ${item.id}`}
                    className="standardized-image"
                  />
                </div>
                <div className="news-card-content flex flex-column justify-between">
                  <p className="news-card-date has-text-grey">
                    {formatDate(new Date(item.created), "date")}
                  </p>
                  <h2 className="title is-4">
                    {isEe ? item.title_ee : item.title_ru}
                  </h2>
                  <p className="content">
                    {isEe ? item.description_ee : item.description_ru}
                  </p>
                  <Link
                    to={`/news/${item.id}`}
                    state={item}
                    className="has-text-primary is-size-5"
                  >
                    {t("readMore")}
                  </Link>
                </div>
              </div>
            </>
          )
        }
      })}
    </div>
  )
}

export default News
