import React from "react"

function NotFound() {
  return (
    <div className="container mt-6" style={{ minHeight: "20vh" }}>
      <div className="columns is-centered">
        <div className="column is-half">
          <h1 className="title has-text-centered">404 Not Found</h1>
        </div>
      </div>
    </div>
  )
}

export default NotFound
