import React from "react"

function Return() {
  return (
    <section className="section conditions">
      <div className="container" style={{ textAlign: "justify" }}>
        <h1 className="title is-4 mb-2 has-text-centered">
          Annetuste tagasimakse tingimused
        </h1>
        <h2 className="subtitle is-5 mb-2">Tagasimakse taotlemise aeg:</h2>
        <p>
          Annetuse tagasimakse saamiseks peab annetaja saatma kirjaliku päringu
          e-posti teel aadressile{" "}
          <a href="mailto:kaasani.kirik@gmail.com">kaasani.kirik@gmail.com</a>{" "}
          kahe nädala jooksul alates makse teostamisest.
        </p>

        <h2 className="subtitle is-5 mt-5 mb-2">
          Nõutav teave tagasimakse taotluse esitamisel:
        </h2>
        <p>Päringu koostamisel tuleb esitada järgmine teave:</p>
        <p>
          Juhul kui on annetuse eest ette makstud sisestades kaardi andmeid,
          tagastatakse raha samale maksekaardile ning kontonumbrit eraldi
          sisestama ei pea. Kui maksite annetuse muul viisil, esitage järgmine
          teave:
        </p>
        <ul style={{ listStyleType: "disc", marginLeft: "1.5rem" }}>
          <li>Annetuse summa</li>
          <li>Makse tuvastamiseks vajalik ID</li>
          <li>IBAN</li>
          <li>Kontoomanik (teie nimi)</li>
        </ul>

        <h2 className="subtitle is-5 mt-5 mb-2">
          Tagasimakse täitmise tähtaeg:
        </h2>
        <p>
          Pärast nõuetekohase päringu kättesaamist sooritatakse tagasimakse 7
          tööpäeva jooksul.
        </p>

        <h2 className="subtitle is-5 mt-5 mb-2">Märkused</h2>
        <p>
          Käesolevad tingimused kehtivad ainult annetuste puhul, mida on tehtud
          veebisaidil
          <strong> kaasani-kirik.ee</strong>. Täiendavate küsimuste korral
          palume võtta ühendust eespool nimetatud e-posti aadressil.
        </p>

        <p className="mt-5">
          <strong>Aadress:</strong> Vabaduse tn 63 Narva-Jõesuu, Narva-Jõesuu
          Ida-Virumaa 29023, Eesti
        </p>
        <p>
          <strong>
            Moskva Patriarhaadi Eesti Õigeusu Kiriku Narva-Jõesuu Jumalaema
            Kaasani Ikooni Kogudus
          </strong>
        </p>
        <p>
          <strong>Registrikood:</strong> 80202491
        </p>
        <p>
          <strong>{"E-post: "}</strong>
          <a href="mailto:kaasani.kirik@gmail.com">kaasani.kirik@gmail.com</a>
        </p>
        <p>
          <strong>Telefoninumber:</strong> +372 512 57 96
        </p>
      </div>
    </section>
  )
}

export default Return
