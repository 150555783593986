import React from "react"
import { useTranslation } from "react-i18next"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"
import { MdPhone } from "react-icons/md"
import { TfiEmail } from "react-icons/tfi"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

const Footer = () => {
  const location = useLocation()
  const { t, i18n } = useTranslation()
  if (location.pathname.includes("admin")) {
    return <></>
  }
  return (
    <footer className="footer has-background-dark has-text-white is-size-5 mt-5">
      <div className="container">
        <div className="content" style={{ width: "100%" }}>
          <div className="columns is-mobile is-multiline ">
            <div className="column is-half-mobile">
              <figure className="image is-128x128 logo">
                <img
                  src="https://doronin.tomberg.tech/images/26.png"
                  alt="Logo"
                />
              </figure>
            </div>
            <div className="column is-half-mobile">
              <div className="is-flex flex-wrap">
                <div style={{ width: "100%" }}>{t("churchOpen")}</div>
                <div style={{ width: "100%" }}>{`${t("mondayShort")}-${t(
                  "fridayShort"
                )} 09${t("timeDelimiter")}00 - 16${t("timeDelimiter")}00`}</div>
                <div style={{ width: "100%" }}>{t("serviceTime")}</div>
                <div style={{ width: "100%" }}>{`${t("saturdayShort")} 17${t(
                  "timeDelimiter"
                )}00`}</div>
                <div style={{ width: "100%" }}>{`${t("sundayShort")} 08${t(
                  "timeDelimiter"
                )}45`}</div>
              </div>
            </div>
            <div className="column is-full-mobile">
              <div className="is-flex flex-wrap">
                <div style={{ width: "100%" }}>
                  Moskva Patriarhaadi Eesti Õigeusu Kiriku Narva-Jõesuu
                  Jumalaema Kaasani Ikooni Kogudus
                </div>
                <div style={{ width: "100%" }}>{`${t(
                  "regNumber"
                )} 80202491`}</div>
                <div style={{ width: "100%" }}>IBAN: EE102200221019575635</div>
              </div>
            </div>
          </div>

          <p>
            <a
              href="mailto:kaasani.kirik@gmail.com"
              className="ml-2"
              style={{ color: "white" }}
            >
              <u>{t("askPriest")}</u>
            </a>
          </p>
          <p>
            <TfiEmail />

            <a
              href="mailto:kaasani.kirik@gmail.com"
              className="ml-2"
              style={{ color: "white" }}
            >
              <u>kaasani.kirik@gmail.com</u>
            </a>
          </p>
          <p>
            <MdPhone />
            <span className="ml-2">+372 512 57 96</span>
          </p>
          <div className="social-links">
            <a
              href="https://www.facebook.com/share/g/Km7MB8MYeB9Hzpvw/?mibextid=K35XfP"
              className="c-white icon fb"
              aria-label="Facebook"
              target="_blank"
            >
              <i>
                <FaFacebookSquare />
              </i>
            </a>

            <a
              target="_blank"
              href="https://www.instagram.com/kaasaniikoonikirik?igsh=MW45dGp2NzV2NGRicw=="
              className="c-white icon insta"
              aria-label="Instagram"
            >
              <i>
                <FaInstagram />
              </i>
            </a>
          </div>
          <p>
            <Link to="/return" className="c-white">
              {t("returnPolicy")}
            </Link>
            <span>{" | "}</span>
            <Link to="/privacy" className="c-white">
              {t("privacyPolicy")}
            </Link>
          </p>
          <p>
            &copy; {new Date().getFullYear()} {t("churchName")}.{" "}
            {t("allRightsReserved")}.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
