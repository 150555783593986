import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "rus",
  resources: {
    est: {
      translation: {
        home: `Kodu`,
        news: `Uudised`,
        schedule: `Ajakava`,
        donate: `Toeta`,
        readMore: `Lähemalt`,
        mondayShort: `E`,
        fridayShort: `R`,
        saturdayShort: `L`,
        sundayShort: `P`,
        churchOpen: `Kirik on avatud:`,
        serviceTime: `Jumalateenistus algab:`,
        timeDelimiter: `.`,
        regNumber: `Registrikood:`,
        churchName: `Narva-Jõesuu Jumalaema Kaasani ikooni kirik`,
        allRightsReserved: `Kõik õigused kaitstud`,
        date: `Kuupäev`,
        time: `Aeg`,
        dayOfWeek: `Päev`,
        eventLabel: `Üritus`,
        prayRequest: `Saada palveks nimed`,
        prayType: `Palve tüüp`,
        forAlive: `Elavate eest`,
        forDead: `Surnute eest`,
        names: `Nimed`,
        submit: `Saada`,
        select: `Vali`,
        askPriest: `Küsi preestrilt`,
        closestEvent: `Lähim üritus`,
        history: `Ajalugu`,
        returnPolicy: `Tagastamise tingimused`,
        privacyPolicy: `Privaatsuspoliitika`,
        recipient: `Saaja:`,
        paymentMethodSelection: `Maksemeetodi saate valida pärast "Maksa" nupule klõpsamist.`,
        readPolicy: `Olen lugenud ja nõustun <a href="https://kaasani-kirik.ee/privacy" target="_blank">privaatsus</a>- ja <a href="https://kaasani-kirik.ee/return" target="_blank">tagastamistingimustega</a>.`,
        pay: `Maksa`,
        testPaymentText: `See on testmakse demo link. Ärge kasutage seda reaalsete maksete tegemiseks`,
      },
    },
    rus: {
      translation: {
        home: `Главная`,
        news: `Новости`,
        schedule: `Расписание`,
        donate: `Пожертвовать`,
        readMore: `Подробнее`,
        mondayShort: `Пн`,
        fridayShort: `Пт`,
        saturdayShort: `Сб`,
        sundayShort: `Вс`,
        churchOpen: `Храм открыт:`,
        serviceTime: `Начало богослужений:`,
        timeDelimiter: `:`,
        regNumber: `Регистрационный номер:`,
        churchName: `Храм Казанской иконы Божией Матери города Нарвы-Йыэсуу`,
        allRightsReserved: `Все права защищены`,
        date: `Дата`,
        time: `Время`,
        dayOfWeek: `День недели`,
        eventLabel: `Событие`,
        prayRequest: `Подать записку для поминовения`,
        prayType: `Тип поминовения`,
        forAlive: `За здравие`,
        forDead: `За упокой`,
        names: `Имена`,
        submit: `Подать`,
        select: `Выбрать`,
        askPriest: `Спросить священника`,
        closestEvent: `Ближайшее событие`,
        history: `История`,
        historyText: ``,
        returnPolicy: `Условия возврата`,
        privacyPolicy: `Политика конфиденциальности`,
        recipient: `Получатель:`,
        paymentMethodSelection: `Выбор метода оплаты возможен после нажатия кнопки "Оплатить".`,
        readPolicy: `Я прочитал и согласен с <a href="https://kaasani-kirik.ee/privacy" target="_blank">политикой конфиденциальности</a> и <a href="https://kaasani-kirik.ee/return" target="_blank">условиями возврата</a>.`,
        testPaymentText: `Это демонстрационная ссылка на тестовый платеж. Не используйте ее для реальных платежей`,
        pay: `Оплатить`,
      },
    },
  },
})

export default i18n
